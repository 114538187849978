import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Container from '../../components/Container';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import GeneralBanner from '../../components/GeneralBanner';

const SecurityPage = () => {
  const intl = useIntl();

  // window.onscroll = function() {
  //   var view = window.innerHeight;
  //   var posTop = document.documentElement.scrollTop;
  //   Array.prototype.forEach.call(
  //     document.querySelectorAll(".js-fade"),
  //     function(e) {
  //       var top = e.offsetTop;
  //       console.log(
  //         `posTop:${posTop},view:${view},top:${top},result:${posTop +
  //           view -
  //           top -
  //           85}`
  //       );
  //       if (posTop + view - top - 200 > 0) {
  //         //png has show
  //         var opac = (posTop + view - top - 200) / 100 + 0.1;
  //         e.style.opacity = opac;
  //       } else {
  //         //png not show
  //         e.style.opacity = 0.1;
  //       }
  //     }
  //   );
  // };

  return (
    <Layout footerBlank>
      <SEO
        title={intl.formatMessage({ id: 'security.title' })}
        description={intl.formatMessage({
          id: 'security.description',
        })}
        keywords={intl.formatMessage({ id: 'security.keywords' })}
      />
      <GeneralBanner>
        <FormattedHTMLMessage id='security.title' />
      </GeneralBanner>
      <Container
        css={css`
          margin-top: 100px;
          ${Desktop} {
            margin-top: 200px;
          }
        `}
      >
        <h1
          css={css`
            width: 100%;
            text-align: left;
            ${Tablet} {
              text-align: center;
            }
          `}
        >
          <FormattedHTMLMessage id='security.priority.title' />
        </h1>
        <h5
          css={css`
            width: 100%;
            text-align: left;
            ${Tablet} {
              text-align: center;
            }
            ${Desktop} {
              padding: 0px 160px;
            }
          `}
        >
          <FormattedHTMLMessage id='security.priority.text' />
        </h5>
      </Container>

      <Container
        css={css`
          margin-top: 50px;
          ${Tablet} {
            margin-top: 100px;
          }
        `}
      >
        {['sfc', 'protection', '2fa', 'algorithm'].map((section) => {
          return (
            <div
              key={section}
              css={css`
                width: 100%;
                display: flex;
                margin-bottom: 40px;
                flex-direction: column;
                ${Tablet} {
                  margin-bottom: 85px;
                  flex-direction: row;
                  align-items: center;
                }
              `}
            >
              <img
                src={`/security/${section}.png`}
                className='js-fade'
                css={css`
                  width: 170px;
                  height: 170px;
                  flex: 0 0 170px;
                  margin-bottom: 20px;
                  ${Tablet} {
                    margin-right: 40px;
                    margin-bottom: 0px;
                  }
                  ${Desktop} {
                    width: 250px;
                    height: 250px;
                    flex: 0 0 250px;
                  }
                `}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                `}
              >
                <h1
                  css={css`
                    font-size: 20px;
                    line-height: 25px;
                    ${Tablet} {
                      font-size: 25px;
                      line-height: 30px;
                    }
                    ${Desktop} {
                      font-size: 35px;
                      line-height: 40px;
                    }
                  `}
                >
                  <FormattedHTMLMessage id={`security.${section}.title`} />
                </h1>
                <h5
                  css={css`
                    margin-bottom: 0px;
                  `}
                >
                  <FormattedHTMLMessage id={`security.${section}.text`} />
                </h5>
              </div>
            </div>
          );
        })}
      </Container>
    </Layout>
  );
};

export default SecurityPage;
